
import { computed } from '@nuxtjs/composition-api';

import { formatSv } from './datefns';
import { usePaginatedQuery } from './pagination';

import { newsLatestQuery } from '@/graphql/queries/newsLatest';
import type { NewsQuery, NewsQueryVariables } from '@/graphql/generated/types';

export type NewsArticle = {
  id: string,
  title: string,
  description: string,
  subtitle: string,
  to: string,
  image: any,
}

type Options = {
  limit?: number
}

export const useLatestNews = (options?: Options) => {
  const opt = Object.assign({}, { limit: 8 }, options);
  const limit = opt.limit;

  const { result, loading, fetchMore, queryKey } = usePaginatedQuery<NewsQuery, NewsQueryVariables>(newsLatestQuery, { limit });

  const latestNews = computed(() => (result.value?.news || []).map(article => ({
    id: article.id,
    title: article.title,
    description: article.lead,
    subtitle: formatSv(new Date(article.publishedAt), 'd MMMM yyyy'),
    to: article.pageUrl,
    image: article.image,
  })));

  return {
    latestNews,
    latestNewsLoading: loading,
    latestNewsQueryKey: queryKey,
    fetchMoreNews: fetchMore,
  };
};
