import { gql } from 'graphql-tag';

import { medium4x3ImageFragment } from './image.fragments';

export const newsLatestQuery = gql`
  query news ($limit: Int, $offset: Int) {
    news (limit: $limit, offset: $offset) {
      id
      title
      lead
      publishedAt
      pageUrl
      image {
        ...Medium4x3ImageData
      }
    }
  }

  ${medium4x3ImageFragment}
`;
