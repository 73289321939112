import { useQuery } from '@vue/apollo-composable';
import type { UseQueryReturn } from '@vue/apollo-composable';
import { DocumentParameter, VariablesParameter, OptionsParameter } from '@vue/apollo-composable/dist/useQuery';
import { computed, ComputedRef, ref } from '@nuxtjs/composition-api';

type OffsetVariables = { offset?: number, limit?: number };
interface UsePaginatedQuery<T, R> extends Omit<UseQueryReturn<T, R>, 'fetchMore'> {
  fetchMore: () => Promise<boolean>
  queryKey: ComputedRef<string>
};

export const usePaginatedQuery = <T, R extends OffsetVariables>(
  query: DocumentParameter<T, R>,
  variables?: VariablesParameter<R>,
  options?: OptionsParameter<T, R>,
): UsePaginatedQuery<T, R> => {
  const { result, fetchMore, query: q, ...rest } = useQuery<T, R>(query, variables, options);
  const completed = ref(false);
  const queryKey = ref('initial');
  const getResultLength = (data = result.value) => (Object.values(data || {})?.[0] as any[] ?? []).length;

  return {
    result,
    query: q,
    queryKey: computed(() => queryKey.value.toString()),
    fetchMore: async () => {
      if (completed.value)
        return false;

      const prevLength = getResultLength();
      await fetchMore({
        // @ts-ignore
        variables: {
          offset: getResultLength(),
        },
      });
      queryKey.value = JSON.stringify({ ...q.value.options.variables, offset: prevLength });

      if (prevLength === getResultLength())
        completed.value = true;

      return true;
    },
    ...rest,
  };
};
