








import { gql } from 'graphql-tag';
import { Component, mixins } from 'nuxt-property-decorator';

import { useLatestNews } from '@/composables/latest-news';
import { blockableBlockColorFragment, blockColorMixin } from '@/composables/block-color';

import { PageBlockMixin } from '@/modules/cms';
import { NewsListBlockDataFragment } from '@/graphql/generated';
import { ComposableMixin } from '@/mixins/composable';

@Component({
  graphqlData: gql`
    fragment NewsListBlockData on NewsListBlock {
      id
      title
      numberOfArticles
      ...BlockColor
    }
    ${blockableBlockColorFragment}
  `,
})
export default class NewsListBlock extends mixins(
  PageBlockMixin<NewsListBlockDataFragment>(),
  ComposableMixin(useLatestNews, () => [{ limit: 8 }]), blockColorMixin) {
  get articles () {
    return this.latestNews?.slice(0, this.data.numberOfArticles) || [];
  }
}
